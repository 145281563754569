import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InnerWrapper from '../../elements/innerWrapper'
import SplitText from '../../components/splitText'
import Grid from '../../elements/grid'
import SectionTransition from '../../elements/activeSectionDefault'
import { breakpoint, getColor } from '../../shared/theme'

import H3 from '../../elements/typography/h3'
import H6 from '../../elements/typography/h6'

const IntroText = styled.div`
	background: ${getColor('black')};
	padding: 60px 0;
	color: ${getColor('white')};

	@media ${breakpoint('tablet')} {
		padding: 60px 0 120px;
	}

	@media ${breakpoint('desktop')} {
		padding: 80px 0 160px;
	}
`

const IntroTextInner = styled(SectionTransition)``

const Subheading = styled.div`
	grid-column: -1 / 1;
	margin-bottom: 20px;

	&:empty
	{
		padding-bottom: 0;
		margin-bottom: 0;
	}

	@media ${breakpoint('tablet')} {
		grid-column: span 2;
	}

	@media ${breakpoint('desktop')} {
		grid-column: span 2;
	}
`

const SubheadingText = styled(H6)`
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0;
	display: inline-block;
	padding-bottom: 0.75em;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 30px;
		height: 1px;
		background: ${getColor('white')};
		visibility: visible;
		transform: ${(props) => (props.isActive ? `scaleX(1)` : `scaleX(0)`)};
		transition: all 200ms ${(prop) => prop.theme.ease};

		@media ${breakpoint('tablet')} {
			display: none;
		}
	}
`

const Intro = styled(H3)`
	display: block;
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: span 7;
	}
	@media (min-width: 1000px) {
		padding-right: 2.5em;
	}

	@media ${breakpoint('laptop')} {
		grid-column: span 6;
	}
`

const PageIntroText = ({ isActive, content }) => {
	if (!content) {
		return null
	}

	const {
		pageHeaderText,
		pageHeaderSubheading: introSubheading,
		footer,
	} = content

	if (
		(pageHeaderText == null || pageHeaderText.length <= 0) &&
		(introSubheading == null || introSubheading.length)
	) {
		return null
	}

	return (
		<IntroText>
			<InnerWrapper isActive={isActive}>
				<IntroTextInner isActive={isActive}>
					<Grid>
						<Subheading>
							{introSubheading && (
								<SubheadingText isActive={isActive}>
									{introSubheading}
								</SubheadingText>
							)}
						</Subheading>

						<Intro>
							{pageHeaderText && (
								<SplitText
									isActive={isActive}
									copy={pageHeaderText}
									footer={footer}
								/>
							)}
						</Intro>
					</Grid>
				</IntroTextInner>
			</InnerWrapper>
		</IntroText>
	)
}

PageIntroText.propTypes = {
	isActive: PropTypes.bool,
	content: PropTypes.object,
}

PageIntroText.defaultProps = {
	isActive: true,
	content: {},
}

export default PageIntroText
