import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const WpLink = ({
	uri,
	title,
	isBlog = false,
	children,
	blogUri = '/more',
}) => {
	const data = useStaticQuery(WP_URL_QUERY)
	const {
		wpgraphql: {
			generalSettings: { url: wordPressUrl },
		},
	} = data

	let siteUrl = wordPressUrl

	const createLocalLink = (uri) => {
		//Remove the trailing /wp from the WP url for Bedrock setups
		siteUrl = siteUrl.replace('/wp', '')

		if (uri === '#') {
			return null
		}

		//Change the new URL to a local one
		let newUri = uri.replace(siteUrl, '')

		//Remove the old site admin URL from the new URI

		newUri = newUri.replace("https://admin.slyletica.com", '')
		newUri = newUri.replace("https://cdn.slyletica.com", '')
		newUri = newUri.replace("https://slyletica-backend.tensq.com.au", '')



		if (isBlog) {
			newUri = blogUri + newUri
		}

		return newUri
	}

	return (
		<Link to={createLocalLink(uri)} title={title}>
			{children}
		</Link>
	)
}

export default WpLink

const WP_URL_QUERY = graphql`
	query GETWPURL {
		wpgraphql {
			generalSettings {
				url
			}
		}
	}
`
