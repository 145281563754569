import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'

export const Wrapper = styled.div`
	display: grid;

	grid-template-columns: ${(props) =>
		props.mobileColumns
			? `repeat(${props.mobileColumns}, minmax(0, 1fr))`
			: `repeat(12, minmax(0, 1fr))`};
	column-gap: ${(props) =>
		props.columnMobileGap ? props.columnMobileGap : '16px'};
	grid-row-gap: ${(props) => (props.rowMobileGap ? props.rowMobileGap : '0')};
	align-items: start;

	@media ${breakpoint('tablet')} {
		grid-template-columns: ${(props) =>
			props.desktopColumns
				? `repeat(${props.desktopColumns}, minmax(0, 1fr))`
				: `repeat(14, minmax(0, 1fr))`};
		column-gap: ${(props) =>
			props.columnDesktopGap ? props.columnDesktopGap : '40px'};
		grid-row-gap: ${(props) =>
			props.rowDesktopGap ? props.rowDesktopGap : '0'};
	}

	@media ${breakpoint('desktop')} {
		column-gap: ${(props) =>
			props.columnXlDesktopGap ? props.columnXlDesktopGap : '40px'};
	}
`

const Grid = (props) => {
	return (
		<Wrapper
			desktopColumns={props.desktopColumns}
			mobileColumns={props.mobileColumns}
			columnXlDesktopGap={props.columnXlDesktopGap}
			columnDesktopGap={props.columnDesktopGap}
			columnMobileGap={props.columnMobileGap}
			rowDesktopGap={props.rowDesktopGap}
			rowMobileGap={props.rowMobileGap}
		>
			{props.children}
		</Wrapper>
	)
}

export default Grid
