import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ease } from '../../shared/theme'

const SectionTransition = styled.div`
	opacity: 0;
	transition: all 600ms ${ease};

	${(props) =>
		props.isActive &&
		css`
			opacity: 1;
		`}
`
export default SectionTransition

SectionTransition.propTypes = {
	isActive: PropTypes.bool,
}

SectionTransition.defaultProps = {
	isActive: PropTypes.false,
}
