import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getColor } from '../../shared/theme'
import WpLink from '../wpLink'

const LinkTag = styled.span`
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0;
	display: inline-block;
	transition: all 200ms ${(prop) => prop.theme.ease};

	h6 {
		padding-bottom: 0.3em;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: ${(props) =>
			props.reversed ? getColor('white') : getColor('black')};
		visibility: visible;
		transform: ${(props) => (props.isActive ? `scaleX(1)` : `scaleX(0)`)};
		transition: all 200ms ${(prop) => prop.theme.ease};
	}

	&:hover {
		opacity: 0.6;

		&::before {
			visibility: hidden;
			transform: scaleX(0);
		}
	}
`

const LinkButton = ({ path, title, reversed, isActive }) => {
	return (
		<WpLink uri={path} title={title}>
			<LinkTag reversed={reversed} isActive={isActive}>
				<h6>{title}</h6>
			</LinkTag>
		</WpLink>
	)
}

LinkButton.propTypes = {
	path: PropTypes.string,
	title: PropTypes.string,
	reversed: PropTypes.bool,
}

LinkButton.propTypes = {
	path: PropTypes.string,
	title: PropTypes.string,
	reversed: PropTypes.bool,
	isActive: PropTypes.bool,
}

LinkButton.defaultProps = {
	path: `#`,
	title: ``,
	reversed: false,
	isActive: true,
}

export default LinkButton
