import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import H1 from '../../elements/typography/h1'
import H2 from '../../elements/typography/h2'

const Copy = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	text-align: center;
	font-weight: 700;
`

const Header = styled(H1)`
	font-weight: inherit;
	letter-spacing: -2px;
`

const Subheading = styled(H2)`
	font-size: 16px;
	line-height: 1.5;
	display: block;
	padding-top: 0.5em;
	font-weight: inherit;

	@media ${breakpoint('tablet')} {
		font-size: 32px;
		line-height: 1;
	}

	@media ${breakpoint('laptop')} {
		padding-top: 1.5em;
	}

	@media ${breakpoint('desktop')} {
		font-size: 40px;
	}
`

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	display: block;
	margin-top: 80px;

	@media ${breakpoint('tablet')} {
		margin-top: 0;
	}

	&::before {
		content: '';
		display: block;
		position: relative;
		width: 100%;
		background: ${getColor('orange')};
		padding-top: ${(props) =>
			props.isPost
				? () => `${(500 / 375) * 100}%`
				: () => `85%`};

		@media ${breakpoint('tablet')} {
			padding-top: ${() => `${(750 / 1920) * 100}%`};
		}
	}
`

const Img = styled.img``

const PageHeaderImage = ({ isActive, image, isPost, content, title }) => {
	const {
		imageSubheading,
		imageHeading,
		pageHeaderImage: heroImage,
		pageHeaderImageMobile: getHeroImageMobile,
	} = content

	const HeroTitle = imageHeading == null ? title : imageHeading
	const heroImageMobile = getHeroImageMobile ? getHeroImageMobile : heroImage

	return (
		<>
			<Wrapper isPost={isPost}>
				{heroImage && (
					<Img src={heroImage.mediaItemUrl} className="bg-cover mobile-hide" />
				)}
				{heroImageMobile && (
					<Img
						src={heroImageMobile.mediaItemUrl}
						className="bg-cover mobile-show"
					/>
				)}
				
				<Copy>
					{imageHeading && (
							<Header className="header">{HeroTitle}</Header>
						)
					}
					{ imageSubheading && (
						<Subheading>{imageSubheading}</Subheading>
						)
					}
				</Copy>

			</Wrapper>
		</>
	)
}

PageHeaderImage.propTypes = {
	isActive: PropTypes.bool,
	single: PropTypes.bool,
	content: PropTypes.object,
}

PageHeaderImage.defaultProps = {
	isActive: true,
	content: {},
	single: false,
}

export default PageHeaderImage
