import React from 'react'
import { HomeIntroText } from '../components/homeHeaderIntro'
import PageHeaderImage from '../components/pageHeaderImage'
import PageIntroText from '../components/pageHeaderIntro'
import SEO from '../components/SEO'
import placeholder from '../images/about-us.png'

const NotFoundPage = () => {
	let pageHeaderContent = {
		footer: null,
		pageHeaderImage: {
			mediaItemUrl: placeholder,
		},
		pageHeaderText: '404 error: Whoops this page does not exist',
	}

	let link = {
		url: '/',
		title: 'Return Home',
	}

	return (
		<>
			<SEO title="404: Not found" />
			<PageHeaderImage content={pageHeaderContent} />
			<HomeIntroText
				subheading={link}
				copy={pageHeaderContent.pageHeaderText}
			/>
		</>
	)
}

export default NotFoundPage
