import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ease, breakpoint } from '../../shared/theme'

const Word = styled.span`
	opacity: 0;
	display: inline-block;
	transform: translateY(20px);
	transition: all 400ms ${ease};

	&.word--1 {
		transition-delay: 100ms;
	}

	&.word--2 {
		transition-delay: 200ms;
	}

	&.word--3 {
		transition-delay: 300ms;
	}

	&.word--4 {
		transition-delay: 400ms;
	}

	&.word--5 {
		transition-delay: 500ms;
	}

	&.word--6 {
		transition-delay: 600ms;
	}

	&.word--7 {
		transition-delay: 700ms;
	}

	&.word--8 {
		transition-delay: 800ms;
	}

	&.word--9 {
		transition-delay: 900ms;
	}

	&.word--10 {
		transition-delay: 1000ms;
	}
`

const IntroText = styled.span`
	display: flex;
	flex-wrap: wrap;

	${(props) =>
		props.isActive &&
		css`
			${Word} {
				opacity: 1;
				transform: none;
			}
		`}

	div + div
	{
		margin-top: 14px;

		@media ${breakpoint('tablet')} {
		  margin-top: 28px;
	  }
	}
`

const Footer = styled.div`
	position: relative;
	margin-top: 40px;
	opacity: ${(props) => (!props.isActive ? 0 : 1)};
	display: inline-block;
	transform: ${(props) => (!props.isActive ? 'translateY(20px)' : 'none')};
	transition: all 400ms ${ease};

	h5 {
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: -0.02em;
	}

	&.word--1 {
		transition-delay: 100ms;
	}

	&.word--2 {
		transition-delay: 200ms;
	}

	&.word--3 {
		transition-delay: 300ms;
	}

	&.word--4 {
		transition-delay: 400ms;
	}

	&.word--5 {
		transition-delay: 500ms;
	}

	&.word--6 {
		transition-delay: 600ms;
	}

	&.word--7 {
		transition-delay: 700ms;
	}

	&.word--8 {
		transition-delay: 800ms;
	}

	&.word--9 {
		transition-delay: 900ms;
	}

	&.word--10 {
		transition-delay: 1000ms;
	}

	&::before {
		content: '';
		height: 2px;
		width: 8px;
		background-color: white;
		margin-bottom: 1.42em;
	}
`

const SplitText = ({ copy, isActive, footer }) => {
	const paragraph = copy.split('<br />')
	const paragraphArr = paragraph.map((paragraph) => {
		return paragraph.split(' ')
	})

	// const stringArr = copy.split(' ')
	const heading = useRef(null)
	const footerWrap = useRef(null)

	useEffect(() => {
		let lineCount = 0
		let lineWidth = 0

		const parentWidth = heading.current.clientWidth
		const children = heading.current.querySelectorAll('div')

		children.forEach((child, index) => {
			const wordSpan = child.querySelectorAll('span')
			lineCount += 1

			wordSpan.forEach((word, index) => {
				lineWidth += word.clientWidth

				if (lineWidth >= parentWidth) {
					lineWidth = word.clientWidth
					lineCount += 1
				}

				let lineClass = `word--${lineCount}`

				word.classList.add(lineClass)

				if (footerWrap.current !== null) {
					if (index >= word.length - 2) {
						footerWrap.current.classList.add(lineClass)
					}
				}
			})
		})
	}, [])

	return (
		<>
			<IntroText isActive={isActive} ref={heading}>
				{paragraphArr.map((stringArr, index) => {
					return (
						<div key={index}>
							{stringArr.map((word, index) => {
								return <Word key={index}>{word}<span style={{whiteSpace: `break-spaces`}}>{' '}</span></Word>
							})}
						</div>
					)
				})}
			</IntroText>
			{footer && (
				<Footer ref={footerWrap} isActive={isActive}>
					<h5>{footer}</h5>
				</Footer>
			)}
		</>
	)
}

SplitText.propTypes = {
	copy: PropTypes.string,
	footer: PropTypes.string,
	isActive: PropTypes.bool,
}

SplitText.defaultProps = {
	copy: '',
	footer: '',
	isActive: PropTypes.false,
}

export default SplitText
